export const ROUTES = {
  PROJECTS: '/nabidka-investic',
  ISSUE: '/investice',
  ABOUT: '/o-nas',
  HOW_IT_WORKS: '/jak-to-funguje',
  SCORING: '/skoring',
  FAQ: '/caste-dotazy',
  CONTACT: '/kontakt',
  FOR_ISSUERS: '/pro-emitenty',
  STATISTICS: '/statistiky',
  CFG_FIRMS: '/seznam-firem-ve-skupine-cfg',

  DICTIONARY: '/slovnik-pojmu',
  COMPANY_ISSUE: '/firemni-dluhopisy',
  CZECH_ISSUE: '/ceske-dluhopisy',
  CZECH_REPUBLIC_ISSUE: '/dluhopisy-ceske-republiky',
  CORPORATE_ISSUE: '/korporatni-dluhopisy',
  ANTI_INFLATION_ISSUE: '/protiinflacni-dluhopisy',
  SAVING_BONDS: '/sporici-dluhopisy',

  STATE_ISSUE: '/statni-dluhopisy',
  INVESTMENT_TO_ISSUE: '/investice-do-dluhopisu',
  SECURED_BONDS: '/zajistene-dluhopisy',
  COMPARISON_OF_SAVING_ACCOUNTS: '/srovnani-sporicich-uctu',
  WHERE_TO_INVEST: '/kam-investovat',
  INVESTMENT_CALCULATOR: '/investicni-kalkulacka',
  MOST_PROFITABLE_INVESTMENTS: '/nejvyhodnejsi-investice',

  CONFLICT_OF_INTEREST: '/stret-zajmu',
} as const
